<template>
  <v-data-table
    :headers="headers"
    :items="itemProducts"
    :loading="isLoading"
    loader-height="1"
    :items-per-page="15"
    :footer-props="{
      itemsPerPageOptions: [15, 25, 50 - 1]
    }"
  >
    <template v-slot:item.attributes.facilityId="{ item }">
      {{ getFacility(item.relationships.facility.data) }}
    </template>

    <template v-slot:item.items="{ item }">
      {{
        item.relationships.items.data
          ? item.relationships.items.data.length
          : '-'
      }}
    </template>
    <template v-slot:item.owner="{ item }">
      {{ item.attributes.customAttributes.ownerFirstName || '-' }}
      {{ item.attributes.customAttributes.ownerLastName || null }}
    </template>
    <template v-slot:item.id="{ item }">
      <BaseTableActions
        module-name="itemProduct"
        :item="item"
        :to-show="{
          name: 'item-product-show',
          params: { itemProductId: item.id }
        }"
        :to-edit="{
          name: 'item-product-edit',
          params: {
            itemProductId: item.id
          }
        }"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import { ORGANIZATION_ID } from '@/common/config.js'
export default {
  data() {
    return {
      userGroups: [],
      isLoading: true,
      facilities: []
    }
  },
  computed: {
    ...mapGetters({
      itemProducts: 'itemProduct/items'
    }),
    headers() {
      return this.createTableHeaders()
    }
  },

  created() {
    this.fetchData()
  },

  methods: {
    ...mapActions({ filterItemProducts: 'itemProduct/filter' }),
    async fetchData() {
      this.isLoading = true
      const organizationId = ORGANIZATION_ID()
      await this.filterItemProducts({ organizationId })
      this.isLoading = false
    },

    removeRow(item) {
      const answer = window.confirm(this.$t('alerts.delete'))
      if (answer) {
        this.$store.dispatch('itemGroupProduct/delete', item)
      }
    },

    createTableHeaders() {
      return [
        {
          text: this.$t('common.code'),
          align: 'start',
          sortable: false,
          value: 'attributes.customAttributes.code'
        },
        {
          text: this.$t('product.model'),
          sortable: false,
          value: 'attributes.customAttributes.model'
        },
        {
          text: this.$t('product.manufacturer'),
          sortable: false,
          value: 'attributes.customAttributes.manufacturer'
        },
        {
          text: this.$t('product.style'),
          sortable: false,
          value: 'attributes.customAttributes.style'
        },
        {
          text: this.$t('product.owner'),
          sortable: false,
          value: 'owner'
        },
        {
          text: '',
          value: 'id',
          sortable: false,
          align: 'end'
        }
      ]
    }
  }
}
</script>
