<template>
  <base-index-layout :title="$tc('product.name', 2)">
    <template #new-button>
      <base-new-button class="mx-4" :to="{ path: 'new' }" append />
    </template>
    <ItemProductIndexTable />
  </base-index-layout>
</template>

<script>
import ItemProductIndexTable from './components/ItemProductIndexTable'

export default {
  components: {
    ItemProductIndexTable
  }
}
</script>
