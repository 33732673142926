var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.itemProducts,"loading":_vm.isLoading,"loader-height":"1","items-per-page":15,"footer-props":{
    itemsPerPageOptions: [15, 25, 50 - 1]
  }},scopedSlots:_vm._u([{key:"item.attributes.facilityId",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getFacility(item.relationships.facility.data))+" ")]}},{key:"item.items",fn:function({ item }){return [_vm._v(" "+_vm._s(item.relationships.items.data ? item.relationships.items.data.length : '-')+" ")]}},{key:"item.owner",fn:function({ item }){return [_vm._v(" "+_vm._s(item.attributes.customAttributes.ownerFirstName || '-')+" "+_vm._s(item.attributes.customAttributes.ownerLastName || null)+" ")]}},{key:"item.id",fn:function({ item }){return [_c('BaseTableActions',{attrs:{"module-name":"itemProduct","item":item,"to-show":{
        name: 'item-product-show',
        params: { itemProductId: item.id }
      },"to-edit":{
        name: 'item-product-edit',
        params: {
          itemProductId: item.id
        }
      }}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }